module AllPages

open Elmish
open Fable.Remoting.Client
open Shared
open Fable.Core.JsInterop
open Feliz.Router

importAll "./css/tailwind.css"

module WebPages =
    type PageName = PageName of string
    let getPageName (PageName str) = str

    type Pages =
        { TemplatePage: PageName
          StartPage: PageName
          IntroductionPage: PageName
           }

    let pages: Pages =
        { TemplatePage = PageName "Template Page"
          StartPage = PageName "Start Page"
          IntroductionPage = PageName "Introduction Page"
           }

let todosApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<ITodosApi>

type GlobalModel =
    { CurrentUrl: string list
      Todos: Todo list }

// Integrating javaScript commands
open Fable.Core

/// implements javaScript scrollToTop function
[<Emit("window.scrollTo(0, 0)")>]
let scrollToTop: unit = jsNative
﻿module SharedElements

open AllPages

open Elmish
open Fable.Remoting.Client
open AllPages
open Fable.Core.JsInterop
open Feliz.Router
open Shared

importAll "./css/tailwind.css"

type Model = { Title: string }

type Msg =
    | NavigateTo of WebPages.PageName
    | BackToStart

let initModel: Model = { Title = "Kung Fu Renaissance" }

let init () = initModel, Cmd.none

let update (msg: Msg) (model: Model) (globalModel: GlobalModel) =
    match msg with
    | NavigateTo page ->
        scrollToTop
        let pageName = WebPages.getPageName page
        model, globalModel, Cmd.navigate (pageName)
    | BackToStart ->
        scrollToTop
        model, globalModel, Cmd.navigate ()

open Feliz
open Feliz.DaisyUI

let globalNavbar (model: Model) (globalModel: GlobalModel) dispatch =
    Daisy.navbar [
//        theme.cyberpunk // <-- this is the theme
        prop.className "mb-2 shadow-lg bg-primary text-primary rounded-box"
        prop.children [
            Daisy.navbarStart [
                Daisy.button.button [
                    button.square
                    button.ghost
                    prop.children [
                        Html.i [ prop.className "fas fa-bars fixed" ]
                    ]
                ]
            ]
            Daisy.navbarCenter [
                prop.children [ Html.span model.Title ]
            ]
            Daisy.navbarEnd [
                Daisy.button.button [
                    button.square
                    button.ghost
                    prop.children [
                        Html.i [
                            prop.className "fas fa-ellipsis-h"
                        ]
                    ]
                ]
            ]
        ]
    ]

let globalFooter (model: Model) (globalModel: GlobalModel) dispatch =
    Daisy.footer [
        prop.className "p-10 bg-primary text-neutral-content"
        prop.children [
            Html.div [
                Daisy.footerTitle "Services"
                Daisy.link [
                    link.hover
                    prop.text "Branding"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Design"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Marketing"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Advertisement"
                ]
            ]
            Html.div [
                Daisy.footerTitle "Company"
                Daisy.link [
                    link.hover
                    prop.text "About us"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Contact"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Jobs"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Press kit"
                ]
            ]
            Html.div [
                Daisy.footerTitle "Legal"
                Daisy.link [
                    link.hover
                    prop.text "Legal"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Terms of use"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Privacy policy"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Cookie policy"
                ]
            ]
        ]
    ]
﻿/// You can use this page as a template for your own custom pages.
module IntroductionPage

open AllPages

open Elmish
open Fable.Remoting.Client
open AllPages
open Fable.Core.JsInterop
open Feliz.Router
open Shared

importAll "./css/tailwind.css"

let thisPage: WebPages.PageName = WebPages.pages.IntroductionPage

type Model = { SharedElements: SharedElements.Model }

type Msg =
    | SharedElementsMsg of SharedElements.Msg
    | NavigateTo of WebPages.PageName
    | BackToStart

let initModel: Model = { SharedElements = fst (SharedElements.init ()) }

let init () = initModel, Cmd.none

let update (msg: Msg) (model: Model) (globalModel: GlobalModel) =
    match msg with
    | SharedElementsMsg m ->
        let l, g, c = SharedElements.update m model.SharedElements globalModel
        { model with SharedElements = l }, g, (Cmd.map SharedElementsMsg c)
    | NavigateTo page ->
        let pageName = WebPages.getPageName page
        model, globalModel, Cmd.navigate (pageName)
    | BackToStart -> model, globalModel, Cmd.navigate ()

open Feliz
open Feliz.DaisyUI

let view (model: Model) (globalModel: GlobalModel) dispatch =
    Daisy.hero [
        prop.className " flex"
        prop.style [
            style.backgroundImageUrl "https://unsplash.it/1200/900?random"
        ]
        prop.children [
            SharedElements.globalNavbar model.SharedElements globalModel (SharedElementsMsg >> dispatch)
            Daisy.heroContent [
                prop.className "container min-h-screen text-center text-neutral-content hero-overlay bg-opacity-60"
                prop.children [
                    Html.div [
                        prop.className "max-w-md "
                        prop.children [
                            Html.h1 [
                                prop.className "mb-5 text-5xl font-bold"
                                prop.text "Do It!"
                            ]
                            Daisy.card [
                                card.bordered
                                prop.children [
                                    Daisy.cardBody [
                                        Daisy.cardTitle "2022/07/05"
                                        Html.p "104.5 kg"
                                    ]
                                ]
                            ]
                            Daisy.card [
                                card.bordered
                                prop.children [
                                    Daisy.cardBody [
                                        Daisy.cardTitle "2022/07/07"
                                        Html.p "Woke up early in the morning"
                                    ]
                                ]
                            ]
                            Daisy.card [
                                card.bordered
                                prop.children [
                                    Daisy.cardBody [
                                        Daisy.cardTitle "2022/07/11"
                                        Html.p "103.5 kg"
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            SharedElements.globalFooter model.SharedElements globalModel (SharedElementsMsg >> dispatch)
        ]
    ]

module Index

open Elmish
open Fable.Remoting.Client
open Feliz.Router
open AllPages
open Shared

type Model =
    { Global: GlobalModel
      StartPage: StartPage.Model
      IntroductionPage: IntroductionPage.Model }

type Msg =
    | StartPageMsg of StartPage.Msg
    | IntroductionPageMsg of IntroductionPage.Msg
    | UrlChanged of string list



let initModel: Model =
    { Global =
        { CurrentUrl = Router.currentUrl ()
          Todos = [] }
      StartPage = fst (StartPage.init ())
      IntroductionPage = fst (IntroductionPage.init ()) }

let init () : Model * Cmd<Msg> =
    let cmd = Cmd.none
    let model = initModel
    model, cmd

let update (msg: Msg) (model: Model) =
    match msg with
    | StartPageMsg m ->
        let l, g, c = StartPage.update m model.StartPage model.Global
        { model with StartPage = l; Global = g }, (Cmd.map StartPageMsg c)
    | IntroductionPageMsg m ->
        let l, g, c = IntroductionPage.update m model.IntroductionPage model.Global
        { model with IntroductionPage = l; Global = g }, (Cmd.map IntroductionPageMsg c)
    | UrlChanged segments ->
        let newGlobal: GlobalModel =
            { CurrentUrl = segments
              Todos = model.Global.Todos }
        { model with Global = newGlobal }, Cmd.none

open Feliz

let view (model: Model) (dispatch: Msg -> unit) =
    let currentPage =
        match model.Global.CurrentUrl with
        | [] -> StartPage.view model.StartPage model.Global (StartPageMsg >> dispatch)
        | [ "Introduction Page" ] -> IntroductionPage.view model.IntroductionPage model.Global (IntroductionPageMsg >> dispatch)
        //        | [ "users"; Route.Query [ "id", Route.Int userId ] ] -> //// KEEP FOR FUTURE Queries
//            Html.h1 (sprintf "Showing user %d" userId)
        | _ -> Html.h1 "Something went wrong. Page not found"

    React.router [
        router.onUrlChanged (UrlChanged >> dispatch)
        router.children currentPage
    ]